(function ($) {

  $('.service-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
  });

})(jQuery);

const prllxTopElms = document.getElementsByClassName('prllx-top');
new simpleParallax(prllxTopElms, {
  orientation: 'down',
  scale: 2,
  overflow: true,
});

const prllxElms = document.getElementsByClassName('prllx');
new simpleParallax(prllxElms, {
  orientation: 'down',
  scale: 1.2,
  overflow: true,
});

const prllxUpElms = document.getElementsByClassName('prllx-up');
new simpleParallax(prllxUpElms, {
  orientation: 'up',
  scale: 1.2,
  overflow: true,
});